const headers = [
  {
    label: "CNPJ",
  },
  {
    label: "Nome",
  },
  {
    label: "Atividade Principal",
  },
  {
    label: "Localidade",
  },
  {
    label: "Aberta em",
  },
  {
    label: "Marketing Obs.",
  },
  {
    label: "Status",
  },
];

const cells = [
  { dataObjectKey: "cnpj" },
  { dataObjectKey: "nome" },
  { dataObjectKey: "atividadePrincipal", customMaxLengthOfCellStrings: 40 },
  { dataObjectKey: "localidade" },
  { dataObjectKey: "abertaEm" },
  { dataObjectKey: "marketingObs" },
  { dataObjectKey: "status" },
];

const rowKeyProperty = "id";

export const tableConfig = { headers, cells, rowKeyProperty };
